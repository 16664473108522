import { httpRequest } from "../handlers/httpRequest";
import Notify from "simple-notify";
import { validateEmail } from "../validation/validateEmail";
import { serializeForm } from "./serializeForm";
import "../validation/integerInput";
import { userTimezone } from "./userTimezone";

export function subscribeEmail(){
    const subscribeEmailBtn = document.querySelectorAll(".subscribeEmailBtn");
    subscribeEmailBtn.forEach(function(ele) {
        ele.addEventListener("click", function() {
            var email=null;

            const self= this,
            btnName = this.innerText;
            
            email = self.closest("form").querySelector(".subscribeEmailInput");
            if(email){
                email=email.value;
            }
            
            if (!validateEmail(email)) {
                new Notify ({
                    status: 'error',
                    title: 'Uh-Oh!',
                    text: 'Please enter valid email address',
                    autoclose: true,
                    effect: 'slide',
                });
                self.closest("form").querySelector(".subscribeEmailInput").focus();
            } else {
                self.setAttribute('disabled', '');
                self.innerText="Subscribing...";

                var form_data = serializeForm(self.closest("form"));
                httpRequest('post', 'backend/subscribe_email', 'json', form_data+"&timezone="+userTimezone)
                .then(function(response) {
                    if(String(response.request) == "success") {
                        // Get the form element
                        var form = document.querySelectorAll("form");
                        if (form) {
                            for (var i = 0; i < form.length; i++) {
                                form[i].reset();
                            }
                        }
        
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'cf',
                            'response':{
                            'result':'thank_you',
                            'success_msg':'Thank you'
                        }
                        });
                        new Notify ({
                            status: 'success',
                            title: 'Success!',
                            text: 'Thank you for subscribing to our newsletter.',
                            autoclose: true,
                            effect: 'slide',
                        });
                    } else {
                        new Notify ({
                            status: 'warning',
                            title: 'Hey!',
                            text: response.msg,
                            autoclose: true,
                            effect: 'slide',
                        });
                    }
                    self.removeAttribute('disabled');
                    self.innerText=btnName;
                })
                .catch(function(error) {
                    new Notify ({
                        status: 'error',
                        title: 'Error',
                        text: 'Something Went wrong! Please try again later...',
                        autoclose: true,
                        effect: 'slide',
                    });
                    self.removeAttribute('disabled');
                    self.innerText=btnName;
                });
            }
        });
    });
}
subscribeEmail();