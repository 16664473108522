import axios from "axios";
import { csrf } from "../common/csrf";

let dataPromise = null;

export function httpRequest(method, url, responseType, data, params) {
    if (!dataPromise) {
        dataPromise = new Promise(function(resolve, reject) {
            const opt ={
                method: method,
                headers: {'X-CSRF-TOKEN': csrf},
                url: url,
                responseType: responseType
            }
            if(data){
                opt.data=data;
            }
            if(params){
                opt.params=params;
            }
            axios(opt)
            .then(function(response) {
                resolve(response.data);
            })
            .catch(function(error) {
                reject(error);
            })
            .finally(function() {
                dataPromise = null; // Reset the promise, whether it was successful or not.
            });
        });
    }
    return dataPromise;
}