import { telephoneValidation } from "../validation/telephoneValidation";
import { httpRequest } from "../handlers/httpRequest";
import Notify from "simple-notify";
import { validateEmail } from "../validation/validateEmail";
import { serializeForm } from "./serializeForm";
import "../validation/integerInput";

var supportEmail = document.querySelector('[name="support_email"]');

if(supportEmail){
    const supportEmailHTML = `<p class="text-secondary-500 text-center text-base font-normal mt-6">If you have any immediate questions or require further assistance, feel free to contact our support team at `+supportEmail.value+`.</p>`;
}

function popupSuccessModal(){
    const formSuccessUI=`
        <div class="p-4 lg:px-14 lg:pb-14 flex flex-col justify-center items-center text-center">
            <svg width="231" height="245" viewBox="0 0 231 245" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1339_4316)">
                <mask id="mask0_1339_4316" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="231" height="245">
                <path d="M231 0H0V245H231V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_1339_4316)">
                <mask id="mask1_1339_4316" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="11" y="-62" width="209" height="365">
                <path d="M11.55 -61.106H219.45V302.071H11.55V-61.106Z" fill="white"/>
                </mask>
                <g mask="url(#mask1_1339_4316)">
                <path d="M201.576 161.317C201.242 161.317 200.908 161.19 200.653 160.937L200.653 160.936L199.345 159.631C199.099 159.385 198.963 159.059 198.963 158.71C198.963 158.362 199.099 158.035 199.345 157.789L200.653 156.484C201.162 155.976 201.99 155.977 202.498 156.484L203.807 157.789C204.053 158.035 204.189 158.362 204.189 158.71C204.189 159.059 204.053 159.385 203.807 159.631L202.498 160.936C202.244 161.19 201.91 161.317 201.576 161.317ZM201.353 160.237C201.476 160.359 201.676 160.359 201.798 160.237L203.107 158.932C203.166 158.873 203.198 158.794 203.198 158.71C203.198 158.626 203.166 158.548 203.107 158.489L201.798 157.183C201.675 157.061 201.476 157.061 201.353 157.183L200.045 158.489C199.986 158.548 199.954 158.626 199.954 158.71C199.954 158.794 199.986 158.873 200.045 158.932L201.353 160.237Z" fill="#FB4D4D"/>
                <path d="M98.6758 69.2302C98.0161 69.2302 97.4795 68.6947 97.4795 68.0365C97.4795 67.3782 98.0161 66.8428 98.6758 66.8428C99.3352 66.8428 99.8718 67.3782 99.8718 68.0365C99.8718 68.6947 99.3352 69.2302 98.6758 69.2302ZM98.6758 67.8315C98.5623 67.8315 98.4701 67.9235 98.4701 68.0365C98.4701 68.1494 98.5623 68.2415 98.6758 68.2415C98.789 68.2415 98.8812 68.1494 98.8812 68.0365C98.8812 67.9235 98.789 67.8315 98.6758 67.8315Z" fill="#FB4D4D"/>
                <path d="M160.403 72.5417C159.196 72.5417 158.214 71.5619 158.214 70.3572C158.214 69.1528 159.196 68.1729 160.403 68.1729C161.61 68.1729 162.592 69.1528 162.592 70.3572C162.592 71.5619 161.61 72.5417 160.403 72.5417ZM160.403 69.1616C159.742 69.1616 159.204 69.698 159.204 70.3572C159.204 71.0166 159.742 71.5528 160.403 71.5528C161.064 71.5528 161.602 71.0166 161.602 70.3572C161.602 69.698 161.064 69.1616 160.403 69.1616Z" fill="#FB4D4D"/>
                <path d="M34.9184 156.058C34.6326 156.058 34.3457 155.952 34.1252 155.74L34.1189 155.734L33.0963 154.713C32.8854 154.503 32.7692 154.221 32.7692 153.919C32.7692 153.616 32.8854 153.334 33.0963 153.124L34.1189 152.103C34.5581 151.665 35.2725 151.666 35.7117 152.103L36.7345 153.124C36.9453 153.334 37.0617 153.616 37.0617 153.919C37.0617 154.221 36.9453 154.503 36.7341 154.714L35.712 155.734C35.495 155.95 35.2074 156.058 34.9184 156.058ZM34.8151 155.03C34.8717 155.083 34.9615 155.084 35.0118 155.034L36.0341 154.014C36.0664 153.982 36.0712 153.941 36.0712 153.919C36.0712 153.897 36.0664 153.855 36.0344 153.823L35.0116 152.803C34.9623 152.754 34.8686 152.754 34.8193 152.803L33.7966 153.823C33.7645 153.855 33.7597 153.897 33.7597 153.919C33.7597 153.941 33.7645 153.982 33.7966 154.014L34.8151 155.03Z" fill="#F5A623"/>
                <path d="M195.644 136.345C195.302 136.345 194.959 136.217 194.694 135.963L194.688 135.957L193.31 134.582C193.058 134.33 192.919 133.993 192.919 133.632C192.919 133.27 193.058 132.933 193.31 132.682L194.688 131.306C195.213 130.783 196.067 130.783 196.592 131.307L197.97 132.682C198.495 133.205 198.495 134.058 197.97 134.582L196.592 135.957C196.333 136.215 195.989 136.345 195.644 136.345ZM195.385 135.254C195.529 135.391 195.756 135.393 195.891 135.258L197.27 133.882C197.404 133.749 197.404 133.515 197.27 133.381L195.892 132.006C195.757 131.872 195.522 131.872 195.388 132.006L194.01 133.381C193.945 133.446 193.91 133.535 193.91 133.632C193.91 133.729 193.945 133.818 194.01 133.883L195.385 135.254Z" fill="#36C874"/>
                <path d="M46.3177 125.271C45.9755 125.271 45.632 125.144 45.3678 124.889L45.3616 124.883L43.983 123.508C43.731 123.256 43.5923 122.919 43.5923 122.558C43.5923 122.197 43.7309 121.859 43.9828 121.608L45.3615 120.233C45.8864 119.709 46.7401 119.709 47.265 120.233L48.6438 121.608C49.1685 122.132 49.1685 122.984 48.6438 123.508L47.265 124.883C47.0062 125.141 46.6627 125.271 46.3177 125.271ZM46.058 124.18C46.203 124.317 46.4296 124.318 46.5648 124.184L47.9434 122.808C48.0773 122.675 48.0773 122.441 47.9434 122.307L46.5648 120.932C46.431 120.798 46.1956 120.798 46.0618 120.932L44.6831 122.307C44.6185 122.372 44.5829 122.461 44.5829 122.558C44.5829 122.655 44.6185 122.744 44.6831 122.809L46.058 124.18Z" fill="#36C874"/>
                <path d="M100.327 199.128C99.9845 199.128 99.641 199.001 99.3768 198.746L99.3706 198.741L97.992 197.366C97.74 197.114 97.6013 196.776 97.6013 196.415C97.6013 196.054 97.74 195.716 97.9918 195.465L99.3704 194.09C99.8954 193.567 100.749 193.566 101.274 194.09L102.653 195.465C103.177 195.989 103.177 196.841 102.653 197.365L101.274 198.741C101.015 198.999 100.672 199.128 100.327 199.128ZM100.067 198.037C100.212 198.174 100.439 198.176 100.574 198.041L101.952 196.666C102.086 196.533 102.086 196.298 101.952 196.165L100.574 194.789C100.44 194.656 100.205 194.656 100.071 194.789L98.6922 196.165C98.6275 196.229 98.5919 196.318 98.5919 196.415C98.5919 196.512 98.6275 196.602 98.6922 196.666L100.067 198.037Z" fill="#36C874"/>
                <path d="M149.234 166.925C148.892 166.925 148.549 166.798 148.284 166.543L148.278 166.537L146.9 165.162C146.648 164.911 146.509 164.573 146.509 164.212C146.509 163.851 146.648 163.513 146.899 163.262L148.278 161.887C148.803 161.363 149.657 161.363 150.182 161.887L151.56 163.262C152.085 163.786 152.085 164.638 151.56 165.162L150.182 166.537C149.923 166.796 149.579 166.925 149.234 166.925ZM148.975 165.834C149.119 165.971 149.346 165.972 149.481 165.838L150.86 164.463C150.994 164.329 150.994 164.095 150.86 163.961L149.481 162.586C149.348 162.452 149.112 162.452 148.978 162.586L147.6 163.962C147.535 164.026 147.499 164.115 147.499 164.212C147.499 164.309 147.535 164.398 147.6 164.463L148.975 165.834Z" fill="#36C874"/>
                <path d="M45.9425 75.9036C45.669 75.9036 45.4472 75.6822 45.4472 75.4093C45.4472 74.864 45.0029 74.4201 44.4566 74.4201C44.183 74.4201 43.9614 74.199 43.9614 73.9258C43.9614 73.6527 44.183 73.4315 44.4566 73.4315C45.0029 73.4315 45.4472 72.9879 45.4472 72.4426C45.4472 72.1695 45.669 71.9482 45.9425 71.9482C46.2162 71.9482 46.4377 72.1695 46.4377 72.4426C46.4377 72.9879 46.8823 73.4315 47.4286 73.4315C47.702 73.4315 47.9237 73.6527 47.9237 73.9258C47.9237 74.199 47.702 74.4201 47.4286 74.4201C46.8823 74.4201 46.4377 74.864 46.4377 75.4093C46.4377 75.6822 46.2162 75.9036 45.9425 75.9036ZM45.7657 73.9258C45.8282 73.9809 45.8874 74.04 45.9425 74.1024C45.9977 74.04 46.0568 73.9809 46.1194 73.9258C46.0568 73.8708 45.9977 73.8117 45.9425 73.7493C45.8874 73.8117 45.8282 73.8708 45.7657 73.9258Z" fill="#F5A623"/>
                <path d="M123.606 55.6692C123.332 55.6692 123.11 55.4478 123.11 55.1748C123.11 54.6296 122.666 54.1857 122.12 54.1857C121.846 54.1857 121.625 53.9646 121.625 53.6915C121.625 53.4184 121.846 53.1971 122.12 53.1971C122.666 53.1971 123.11 52.7536 123.11 52.2083C123.11 51.9352 123.332 51.7139 123.606 51.7139C123.879 51.7139 124.101 51.9352 124.101 52.2083C124.101 52.7536 124.545 53.1971 125.092 53.1971C125.365 53.1971 125.587 53.4184 125.587 53.6915C125.587 53.9646 125.365 54.1857 125.092 54.1857C124.545 54.1857 124.101 54.6296 124.101 55.1748C124.101 55.4478 123.879 55.6692 123.606 55.6692ZM123.429 53.6915C123.491 53.7465 123.55 53.8056 123.606 53.868C123.661 53.8056 123.72 53.7465 123.782 53.6915C123.72 53.6365 123.661 53.5774 123.606 53.5149C123.55 53.5774 123.491 53.6365 123.429 53.6915Z" fill="#36C874"/>
                <path d="M33.1729 102.647C32.8993 102.647 32.6776 102.425 32.6776 102.152C32.6776 101.607 32.2332 101.163 31.687 101.163C31.4134 101.163 31.1918 100.942 31.1918 100.669C31.1918 100.396 31.4134 100.175 31.687 100.175C32.2332 100.175 32.6776 99.7311 32.6776 99.1858C32.6776 98.9127 32.8993 98.6914 33.1729 98.6914C33.4465 98.6914 33.668 98.9127 33.668 99.1858C33.668 99.7311 34.1127 100.175 34.6589 100.175C34.9323 100.175 35.1541 100.396 35.1541 100.669C35.1541 100.942 34.9323 101.163 34.6589 101.163C34.1127 101.163 33.668 101.607 33.668 102.152C33.668 102.425 33.4465 102.647 33.1729 102.647ZM32.996 100.669C33.0586 100.724 33.1177 100.783 33.1729 100.846C33.228 100.783 33.2872 100.724 33.3497 100.669C33.2872 100.614 33.228 100.555 33.1729 100.492C33.1177 100.555 33.0586 100.614 32.996 100.669Z" fill="#36C874"/>
                <path d="M192.697 83.7456C192.423 83.7456 192.202 83.5241 192.202 83.2511C192.202 81.7061 190.943 80.4492 189.395 80.4492C189.122 80.4492 188.9 80.2281 188.9 79.955C188.9 79.6818 189.122 79.4606 189.395 79.4606C190.943 79.4606 192.202 78.2038 192.202 76.659C192.202 76.3859 192.423 76.1646 192.697 76.1646C192.971 76.1646 193.192 76.3859 193.192 76.659C193.192 78.2038 194.451 79.4606 195.999 79.4606C196.273 79.4606 196.494 79.6818 196.494 79.955C196.494 80.2281 196.273 80.4492 195.999 80.4492C194.451 80.4492 193.192 81.7061 193.192 83.2511C193.192 83.5241 192.971 83.7456 192.697 83.7456ZM191.269 79.955C191.864 80.2931 192.358 80.7871 192.697 81.3807C193.036 80.7871 193.531 80.2931 194.125 79.955C193.531 79.6168 193.036 79.1229 192.697 78.5292C192.358 79.1229 191.864 79.6168 191.269 79.955Z" fill="#36C874"/>
                <path d="M61.6694 64.3127C61.3958 64.3127 61.1741 64.0912 61.1741 63.8182C61.1741 63.4481 60.8723 63.1468 60.5011 63.1468C60.2276 63.1468 60.0059 62.9257 60.0059 62.6526C60.0059 62.3795 60.2276 62.1582 60.5011 62.1582C60.8723 62.1582 61.1741 61.8571 61.1741 61.4871C61.1741 61.2139 61.3958 60.9927 61.6694 60.9927C61.943 60.9927 62.1645 61.2139 62.1645 61.4871C62.1645 61.8571 62.4666 62.1582 62.8377 62.1582C63.1112 62.1582 63.3331 62.3795 63.3331 62.6526C63.3331 62.9257 63.1112 63.1468 62.8377 63.1468C62.4666 63.1468 62.1645 63.4481 62.1645 63.8182C62.1645 64.0912 61.943 64.3127 61.6694 64.3127Z" fill="#FB4D4D"/>
                </g>
                <path d="M115.5 155.949C95.8912 155.949 79.9703 140.056 79.9703 120.482C79.9703 100.908 95.8912 85.0158 115.5 85.0158C135.109 85.0158 151.03 100.908 151.03 120.482C151.03 140.056 135.109 155.949 115.5 155.949Z" fill="#27AE60"/>
                <path d="M115.5 155.949C95.8912 155.949 79.9703 140.056 79.9703 120.482C79.9703 100.908 95.8912 85.0158 115.5 85.0158C135.109 85.0158 151.03 100.908 151.03 120.482C151.03 140.056 135.109 155.949 115.5 155.949Z" stroke="#27AE60" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M103.918 120.83L111.407 128.306L127.082 112.659" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                </g>
                <defs>
                <clipPath id="clip0_1339_4316">
                <rect width="231" height="245" fill="white"/>
                </clipPath>
                </defs>
            </svg>
            <h1 class="text-secondary-900 text-xl lg:text-2xl font-semibold">🎉 Success! Your Form Has Been Submitted 🎉</h1>
            <p class="text-secondary-500 text-base font-normal mt-8">Thank you for your interest in our properties. Your inquiry has been successfully submitted. Our dedicated team will review your request and reach out to you shortly with more information.</p>
            `+supportEmailHTML+`
            <button data-modal-hide="contactModal" class="closeModal text-secondary-700 rounded-sm border border-secondary-100 text-center uppercase tracking-[0.063rem] mt-6 w-full lg:w-auto px-4 lg:px-40 py-3.5">Close</button>
        </div>
    `;
    document.querySelector(".contactModalBody").innerHTML = formSuccessUI;
}


export function contactForm(){
    const sendMailBtn = document.querySelectorAll(".sendMail");
    sendMailBtn.forEach(function(ele) {
        ele.addEventListener("click", function() {
            var name=null, email=null, phone=null, numberError=null;

            const self= this,
            btnName = this.innerText;
            
            name = self.closest("form").querySelector(".name");
            if(name){
                name=name.value;
            }
            email = self.closest("form").querySelector(".email");
            if(email){
                email=email.value;
            }

            const phoneInputField=self.closest("form").querySelector(".phoneNumber");
            if(phoneInputField){
                phone = window.intlTelInputGlobals.getInstance(phoneInputField).getNumber();
                // phone number validation
                var numberError = window.intlTelInputGlobals.getInstance(phoneInputField).isValidNumber();
            }
            var msg =self.closest("form").getAttribute("data-msg");
            if(self.closest("form").querySelector(".message")){
                msg = self.closest("form").querySelector(".message").value;
            }
            if (name == "") {
            new Notify ({
                status: 'error',
                title: 'Uh-Oh!',
                text: 'Please enter your name',
                autoclose: true,
                effect: 'slide',
            });
            self.closest("form").querySelector(".name").focus();
            }else if (name && name.length > 30) {
            new Notify ({
                status: 'error',
                title: 'Uh-Oh!',
                text: 'Name is too long',
                autoclose: true,
                effect: 'slide',
            });
            self.closest("form").querySelector(".name").focus();
            } else if (email == "") {
                new Notify ({
                    status: 'error',
                    title: 'Uh-Oh!',
                    text: 'Please enter your email address',
                    autoclose: true,
                    effect: 'slide',
                });
                self.closest("form").querySelector(".email").focus();
            } else if (!validateEmail(email)) {
            new Notify ({
                status: 'error',
                title: 'Uh-Oh!',
                text: 'Please enter valid email address',
                autoclose: true,
                effect: 'slide',
            });
            self.closest("form").querySelector(".email").focus();
            }else if (phone == "") {
                new Notify ({
                    status: 'error',
                    title: 'Uh-Oh!',
                    text: 'Please enter your phone number',
                    autoclose: true,
                    effect: 'slide',
                });
                self.closest("form").querySelector(".phoneNumber").focus();
            }else if (numberError !==null && !numberError) {
                new Notify ({
                    status: 'error',
                    title: 'Uh-Oh!',
                    text: 'Invalid phone number length for the selected country',
                    autoclose: true,
                    effect: 'slide',
                });
                self.closest("form").querySelector(".phoneNumber").focus();
            }
            else if (msg && msg.length > 500) {
                new Notify ({
                    status: 'error',
                    title: 'Uh-Oh!',
                    text: 'Message is too long',
                    autoclose: true,
                    effect: 'slide',
                });
                self.closest("form").querySelector(".message").focus();
            } else if (msg == "") {
                new Notify ({
                    status: 'error',
                    title: 'Uh-Oh!',
                    text: 'Please write your message',
                    autoclose: true,
                    effect: 'slide',
                });
                self.closest("form").querySelector(".message").focus();
            } else {
                self.setAttribute('disabled', '');
                self.innerText="Sending...";

                var form_data = serializeForm(self.closest("form"));
                const currentURL = window.location.href;
                var h1Heading="";
                if(document.querySelector("h1")){
                    h1Heading = document.querySelector("h1").innerText;
                }
                httpRequest('post', 'backend/inquiry_form', 'json', form_data+"&inquiry_page_url="+currentURL+"&inquiry_page_title="+h1Heading)
                .then(function(response) {
                    if(String(response.request) == "true") {        
                        // Get the form element
                        var form = document.querySelectorAll("form");
                        if (form) {
                            for (var i = 0; i < form.length; i++) {
                                form[i].reset();
                            }
                        }
        
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'cf',
                            'response':{
                            'result':'thank_you',
                            'success_msg':'Thank you'
                        }
                        });

                        if(document.querySelector("#contactModal").classList.contains("hidden")){
                            let openModal = document.querySelector('[data-modal-target="contactModal"]');
                            openModal.click();
                        }
                        popupSuccessModal();
                    } else {
                        new Notify ({
                            status: 'error',
                            title: 'Error',
                            text: 'Something Went wrong! Please try again later...',
                            autoclose: true,
                            effect: 'slide',
                        });
                    }
                    self.removeAttribute('disabled');
                    self.innerText=btnName;
                })
                .catch(function(error) {
                    new Notify ({
                        status: 'error',
                        title: 'Error',
                        text: 'Something Went wrong! Please try again later...',
                        autoclose: true,
                        effect: 'slide',
                    });
                    self.removeAttribute('disabled');
                    self.innerText=btnName;
                });
            }
        });
    });
}
contactForm();

const contactModalBtns = document.querySelectorAll(".contactModalBtn");
if(contactModalBtns){
    contactModalBtns.forEach(element => {
        element.addEventListener("click", function () {
            document.querySelectorAll(".phoneNumber").forEach(telephoneValidation);
        });
    });
}

const contactModalLink = document.querySelectorAll('a[href="#contact_modal"], a[href="#modal_contact"]');
if(contactModalLink){
    contactModalLink.forEach(element => {
        element.addEventListener("click", function () {
            let openModal = document.querySelector('[data-modal-target="contactModal"]');
            openModal.click();
        });
    });
}
document.body.addEventListener("click", function(event) {
    if (event.target.classList.contains("closeModal")) {
        let closeModal = document.querySelector('[data-modal-hide="contactModal"]');
        closeModal.click();
        // Manually hide the backdrop
        const backdrop = document.querySelector('[modal-backdrop]');
        if (backdrop) {
            backdrop.remove();
        }
    }
});