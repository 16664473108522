export function integerInput(){
    document.querySelectorAll(".phoneNumber").forEach(function (numberInput) {
        numberInput.addEventListener("keydown", function (event) {
            // Allow backspace and delete keys
            if (event.key === "Backspace" || event.key === "Delete" || event.key === "+") {
                return;
            }
            // Allow only numbers
            if (isNaN(event.key)) {
                event.preventDefault();
            }
        });
    });  
}integerInput();