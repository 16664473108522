import intlTelInput from 'intl-tel-input'; 
import { getUserLocationInfo } from '../common/getUserLocationInfo';

const intlOptions={
  preferredCountries: ["AE"],
  separateDialCode: true,
   lazyLoading: true,
   showFlags:true,
   placeholderNumberType: "MOBILE",
  utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
}

if (typeof getUserLocationInfo === 'function') {
  intlOptions.geoIpLookup=getUserLocationInfo;
} else if (typeof getUserLocationInfo === 'object') {
  intlOptions.initialCountry=getUserLocationInfo()['country_code'];
} else {
  intlOptions.initialCountry='AE';
}

export function telephoneValidation(phoneInputField) {
    intlTelInput(phoneInputField, intlOptions);
}

document.addEventListener("DOMContentLoaded", function() {
  document.querySelectorAll(".phoneNumber").forEach(telephoneValidation);
});