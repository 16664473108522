export function megaMenuDropDown() {
    const megaDropDownBtns = document.querySelectorAll('.megaDropDownBtn');
    let isMouseInside = false;
  
    megaDropDownBtns.forEach(dropdownBtn => {
      const megaDropdownMenu = dropdownBtn.closest('li').querySelector('.megaDropDownMenu');
      megaDropdownMenu.removeAttribute("hidden");
      const megaDropDownMenuPointer = dropdownBtn.closest('li').querySelector('.megaDropDownMenuPointer');
      megaDropDownMenuPointer.removeAttribute("hidden");
  
      // Show the menu when mouse enters the button
      dropdownBtn.addEventListener('mouseenter', () => {
        if (isMouseInside) {
          isMouseInside = false;
          return;
        }
        megaDropdownMenu.classList.remove("flip-90");
        megaDropDownMenuPointer.classList.remove("flip-90");
        dropdownBtn.classList.add("active");
      });
  
      // Hide the menu when mouse leaves the button
      dropdownBtn.addEventListener('mouseleave', () => {
        if (isMouseInside) {
          isMouseInside = false;
          return;
        }
        megaDropdownMenu.classList.add("flip-90");
        megaDropDownMenuPointer.classList.add("flip-90");
        dropdownBtn.classList.remove("active");
      });
  
      // Prevent hiding the menu when mouse enters the menu
      megaDropdownMenu.addEventListener('mouseenter', () => {
        isMouseInside = true;
        if (!dropdownBtn.classList.contains("active")) {
          megaDropdownMenu.classList.remove("flip-90");
          megaDropDownMenuPointer.classList.remove("flip-90");
          dropdownBtn.classList.add("active");
        }
      });
  
      // Revert to hiding the menu when mouse leaves the menu
      megaDropdownMenu.addEventListener('mouseleave', () => {
        if (isMouseInside) {
          isMouseInside = false;
          return;
        }
        megaDropdownMenu.classList.add("flip-90");
        megaDropDownMenuPointer.classList.add("flip-90");
        dropdownBtn.classList.remove("active");
      });
    });
  }
  megaMenuDropDown();